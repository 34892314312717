<template>
    <base-page>

        <el-card style="margin: 5px 0px 5px 0px">

            <el-collapse accordion style="border: none">
                <el-collapse-item name="1" style="border: none">
                    <template slot="title">
                        <div slot="header" class="clearfix">
                            <titlu-pagina Titlu="Stoc marfa clienti" @on_add_clicked="show_dialog()" :AdaugaVisible="false" />
                        </div>
                    </template>
                    <div class="filtre">
                        <el-form @submit.prevent.native="refresh_info()" label-position="top">
                            <el-row>
                                <el-col>
                                    <el-form-item label="Gestiune">
                                        <el-select class="full-width" v-model="Filters.IdGestiune" filterable>
                                            <el-option v-for="item in Info.Gestiuni" :key="item.Id" :label="item.Nume" :value="item.Id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col style="width: 100%">
                                    <div class="footer-form">
                                        <el-button type="primary" native-type="submit">Aplica</el-button>
                                        <el-button type="danger" @click="reset()">Reseteaza</el-button>
                                        <el-button type="info" native-type="submit" @click="print()" :disabled="printBTN">Printeaza</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>

        <el-table :header-cell-style="{ background: '#393E46' }" :data="Results">
            <el-table-column prop="CodProdus" label="Cod"></el-table-column>
            <el-table-column prop="Nume" label="Denumire"></el-table-column>
            <!--  -->
            <el-table-column prop="cantitate" label="Stoc"></el-table-column>
            <el-table-column prop="NumeClient" label="Client"></el-table-column>
            <!-- <el-table-column prop="" label="Data">???</el-table-column> -->
        </el-table>

    </base-page>
</template>
  
<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Clienti_dialog from "@/pages/clienti/Clienti_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
    name: 'app',
    extends: BasePage,
    components: {
        "base-page": BasePage,
        "Clienti-dialog": Clienti_dialog,
        "titlu-pagina": TitluPagina,
    },
    data() {
        return {
            Results: [],
            Info: {Gestiuni:[]},
            Filters: {
                IdGestiune: '',

            },
            printBTN: true,
        }
    },
    methods: {
        async get_info() {
            var raspuns = await this.post("Rapoarte/Stoc_marfa_clienti/get_gestiuni_avizare");
            console.log(raspuns)
            this.Info.Gestiuni = raspuns.gestiuni_avizare;

            console.log(this.Info.Gestiuni)

        },
        async refresh_info() {
            var response = await this.post("Rapoarte/Stoc_marfa_clienti/get_raport_StocMarfaClient", { Filters: this.Filters });

            this.Results = response.produse;
            // this.Results.client = this.Clienti[this.Filters.IdClient - 1].Nume;
            
            this.printBTN = false;
        },
        reset() {
            this.Filters = {
                IdGestiune: '',
            };
            this.refresh_info();
        },
        async print(){
            let res = await this.post('Rapoarte/Stoc_marfa_clienti/print_stoc_clienti', {Results: this.Results, NumeClient: this.Results.client});
            console.log(this.Results);
            let doc = window.open("", "_blank");
            doc.document.write(res.html);
        }
    },
    mounted: function () {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
}
</script>
